const padhaiTime = {
  title: "Padhai Time",
  primaryBtn: {
    title: "Visit Site",
    link: "https://www.padhaitime.com",
  },
  image: "./static/padhaiTime.png",

  description:
    "PadhaiTime is a website that provides free study material to learn Machine Learning. I wrote Backend, Frontend from scratch and added Content management system",
};

const vrVis = {
  title: "Virtual Reality Data Visualization",
  // primaryBtn: {
  //   title: "Visit Site",
  //   link: "https://www.padhaitime.com",
  // },
  image: "./static/vrvis.png",

  description:
    "Utilized AR/VR technology and data visualization techniques to explore the impact of light on human well-being. The approach employs the Unity game engine for visualization and assesses user experience",
};

const serverlessPlugin = {
  title: "Dynamodb Streams Plugin",
  primaryBtn: {
    title: "Github",
    link: "https://github.com/deepakandgupta/serverless-dynamodb-stream-arn-plugin",
  },
  secondaryBtn: {
    title: "NPM Link",
    link: "https://www.npmjs.com/package/serverless-dynamodb-stream-arn-plugin",
  },
  image: "./static/serverlessPlugin.png",
  description:
    "Open source npm plugin that fetches and adds existing AWS DynamoDB Table streams to serverless.yml using table names. Downloaded 25,000+ times",
};

const flappyBird = {
  title: "Neural Network Flappy Bird",
  primaryBtn: {
    title: "Github",
    link: "https://github.com/deepakandgupta/NeuroEvolution-Flappy-bird",
  },
  secondaryBtn: {
    title: "Demo",
    link: "https://deepakandgupta.github.io/NeuroEvolution-Flappy-bird/",
  },
  image: "./static/flappyBird.png",
  description:
    "An implementation based on NeuroEvolution of Augmenting Topologies(NEAT) genetic algorithm on game flappy bird using a custom neural network",
};

const sketchBoard = {
  title: "Idea Board",
  primaryBtn: {
    title: "Demo",
    link: "https://sketch-wizer.netlify.app/",
  },
  image: "./static/sketch_board.gif",
  description:
    "A drawing board with custom components like neural network, binary trees, a custom video player that works in canvas and more using React",
};

const doodleJump = {
  title: "Doodle Jump Clone",
  primaryBtn: {
    title: "Github",
    link: "https://github.com/deepakandgupta/game_wiz/tree/doodle-jump",
  },
  image: "./static/doodleJump.png",

  description: "Clone of the classic Doodle Jump game made using Unity",
};

const chromeDino = {
  title: "Chrome Dino Clone",
  primaryBtn: {
    title: "Github",
    link: "https://github.com/deepakandgupta/unity_chrome_dino",
  },
  image: "./static/chromeDino.png",

  description: "Clone of the classic Chrome Dino game made using Unity",
};

const projects = [
  padhaiTime,
  vrVis,
  serverlessPlugin,
  flappyBird,
  sketchBoard,
  doodleJump,
  chromeDino,
];

export default projects;
