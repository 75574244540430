import React from 'react'
import './index.css'
import Button from '../../Atoms/Button';

interface ProjectCardProps {
    title: string;
    image: string;
    description: string;
    primaryBtn? : {
        title: string;
        link: string;
    }
    secondaryBtn? : {
        title: string;
        link: string;
    }
}

function ProjectCard(props: ProjectCardProps) {
    const {
        title,
        image,
        description,
        primaryBtn,
        secondaryBtn,
    } = props
    return (
        <div className="card">
            <img
                className="card-image"
                src={image}
                alt="Card Image"
            />
            <div className="card-content">
                <div className='card-text-content'>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
                <div className='card-button-group'>
                    {primaryBtn ? <Button title={primaryBtn.title}  link={primaryBtn.link}/> : <></>}
                    {secondaryBtn? <Button title={secondaryBtn.title} link={secondaryBtn.link}/> : <></>}
                </div>
            </div>
        </div>
    );
}

export default ProjectCard