import React from 'react'
import Carousel from 'react-multi-carousel';
import './index.css'

function ContactPage() {
    return (
        <section id='contact-section' className='contact-section center-element'>
            <h1 className='contact-heading'>Contact</h1>
            <div className="contact-links">
                <p className='contact-link'><i className="fa-regular fa-envelope fa-custom"></i>deepakg@vt.edu</p>
                <div className='center-element'>
                    <a className="fa-brands fa-linkedin" target="_blank" href="https://www.linkedin.com/in/deepakandgupta/"></a>
                    <a className="fa-brands fa-github" target="_blank" href="https://github.com/deepakandgupta"></a>
                </div>
            </div>
        </section>
    )
}

export default ContactPage