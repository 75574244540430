import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import WelcomeSection from './Components/WelcomeSection';
import ProjectsSection from './Components/ProjectsSection';
import Header from './Components/Header';
import AnimatedBG from './Components/AnimatedBG';
import ContactPage from './Components/ContactPage';

function App() {

  const targetRef = useRef<HTMLDivElement | null>(null);

  const handleScrollClick = () => {
    // Scroll to the target element
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // or 'end' or 'center'
        inline: 'nearest', // or 'start' or 'end'
      });
    }
  };

  return (
    <div className="App">
      <AnimatedBG />
      <Header />
      <div className='content' id='main-content'>
        <WelcomeSection />
        <ProjectsSection/>
        <ContactPage/>
      </div>
    </div>
  );
}

export default App;
