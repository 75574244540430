import React, { useEffect, useState } from 'react'
import './index.css'
const divCount = 100;
function AnimatedBG() {
    useEffect(() => {
      window.addEventListener('scroll', handlePageScroll);
    
      return () => {
        window.removeEventListener('scroll', handlePageScroll);

      }
    }, [])
    
    const handlePageScroll = () => {
        console.log('Yo the page scrolled');
        
    }


    return (
        <div className="animated-bg">
            {Array.from({ length: divCount }, (_, index) => (
                <div key={`animated-bg-obj-${index}`} className='bg-obj' style={{ left: `${150 * (Math.random() * 10)}px`, top: `${150 * (Math.random() * 10)}px` }}></div>
            ))}
        </div>
    )
}

export default AnimatedBG