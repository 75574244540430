import React, { CSSProperties, MouseEventHandler } from "react";
import "./index.css";

function Button(props: {
  title: string;
  outline?: boolean;
  rounded?: boolean;
  fullSize?: boolean;
  style?: CSSProperties | undefined;
  link?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) {
  const { title, outline, rounded, fullSize, style, link, onClick } = props;
  const classes = `button ${outline ? "outline" : ""} ${
    rounded ? "rounded" : ""
  } ${fullSize ? "fullSize" : ""}`;

  return (
    <a className="btn-link" href={link} target='_blank'>
      <button className={classes} onClick={onClick} style={style}>
        <span>{title}</span>
      </button>
    </a>
  );
}

export default Button;
