import React from 'react'
import ProjectCard from '../ProjectCard'
import './index.css'
import Button from '../../Atoms/Button'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import projects from './projectsData';


function ProjectsSection() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1000, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 480, min: 0 },
            items: 1
        }
    };

    return (
        <section className='projects-section' id='projects-section'>
                <h1 className='center-element heading-projects'>Projects</h1>

            <Carousel
                responsive={responsive}
                swipeable={false}
                draggable={false}
                showDots={true}
                infinite={true}
                keyBoardControl={true}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
                dotListClass="custom-dot-list-style"
                renderDotsOutside={true}
                partialVisbile={true}
            >
                {projects.map(project => {
                    return <ProjectCard {...project}/>
                })}

            </Carousel>
            <div className='center-element explore-more-btn'>
                {/* <Button title='More Projects' outline fullSize={false}/> */}
            </div>

        </section>
    )
}

export default ProjectsSection